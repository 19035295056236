<template>
  <div class="container-fluid banner">
    <div class="row text-center">
      <div class="banner-text">
        <h2 classs="mb-4">{{ $t('evaluate.title') }}</h2>
        <p>{{ $t('evaluate.intro') }}</p>
        <localized-link :to="{ name: 'Survey' }" class="mt-4"
          ><app-button> {{ $t('evaluate.start') }}</app-button></localized-link
        >
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row row-banner survey-row">
      <div class="col-12 col-lg-8">
        <div class="text-content">
          <h4 class="mb-4">{{ $t('evaluate.description_title') }}</h4>
          <p class="mb-4">
            {{ $t('evaluate.description1') }}
          </p>
          <p class="mb-4">
            {{ $t('evaluate.description2') }}
          </p>
          <ul>
            <li>{{ $t('evaluate.description3') }}</li>
            <li>{{ $t('evaluate.description4') }}</li>
            <li>{{ $t('evaluate.description5') }}</li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-4 img-col">
        <img class="img-fluid" src="@/assets/img/fuel.png" alt="fuel-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Evaluate',
};
</script>

<style lang="scss">
@include bp-down(md) {
  .survey-intro {
    font-size: 18px;
  }
}

.survey-row {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .text-content {
    max-width: 70%;
    margin: 0 auto;
  }
  .img-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      max-width: 80%;
    }
  }
  .col-lg-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@include bp-down(lg) {
  .survey-row {
    margin-top: 1rem;
    .img-col {
      margin-top: 1rem;
      img {
        max-width: 50%;
      }
    }
  }
}
</style>
